import React from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { theme } from 'theme';

const useStyles = makeStyles(() => ({
  accentImg: {
    height: 195,
    marginBottom: theme.spacing(4),
  },
  container: {
    padding: theme.spacing(0, 6),
    position: 'relative',
  },
  heading: {
    fontWeight: 800,
    fontSize: '3.125rem',
    lineHeight: '4.25rem',
    marginBottom: theme.spacing(2),
  },
  subheading: {
    marginBottom: theme.spacing(10),
  },
  description: {
    marginBottom: theme.spacing(6),
  },
}));

const DemoRequestSuccess = ({ ...restProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      width={1}
      pt={isLgUp ? 40 : 20}
      pb={isLgUp ? 40 : 20}
      overflow="hidden"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...restProps}
    >
      <Box
        width={436}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="request-a-demo-icon"
          src="/images/bulb-in-the-box.svg"
          className={classes.accentImg}
        />
        <Typography variant="h2" align="center" color="textSecondary" className={classes.heading}>
          {t('NICE')}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
          className={classes.subheading}
        >
          {t('YOU_ARE_ALL_SIGNED_UP')}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          className={classes.description}
        >
          {t('WE_WILL_CONTACT_YOU')}
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary">
          {t('LOOKING_FORWARD_MEETING_YOU')}
        </Typography>
      </Box>
    </Box>
  );
};

export { DemoRequestSuccess };
