import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { BulletPoint, Button, TextField, Checkbox, Loader, OutboundIcon } from 'components';

import { theme } from 'theme';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';

import { generateKey } from 'shared/functions/generateKey';
import { formStates } from 'shared/constants/formStates';
import { applyForJob } from 'shared/services';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles(() => ({
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  heading: {
    fontWeight: 700,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  bulletPoint: {
    marginLeft: theme.spacing(4),
    lineHeight: '2.25rem',
    [theme.breakpoints.down('md')]: {
      lineHeight: '1.875rem',
    },
  },
  normal: {
    fontWeight: 400,
  },
  strong: {
    fontWeight: 700,
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    marginTop: theme.spacing(4),
  },
  checkbox: {
    color: theme.palette.common.lightGrayishVioletAlt,
    padding: 0,
    marginRight: theme.spacing(3),
  },
  infoWrapper: {
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6, 6),
    },
  },
  accentImg: {
    marginBottom: theme.spacing(6),
  },
  successHeading: {
    marginBottom: theme.spacing(4),
  },
  link: {
    wordBreak: 'break-all',
  },
}));

const CareersItem = ({ data }) => {
  const { t } = useTranslation();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const classes = useStyles();
  const [formState, setFormState] = useState(formStates.PRISTINE);
  const [isPending, setIsPending] = useState(false);

  const { title, description, paragraphs, link } = data;

  const [preloadedImages] = useState(['/images/bulb-blue.svg']);

  useEffect(() => {
    if (preloadedImages && preloadedImages.length) {
      preloadedImages.forEach((imagePath) => {
        new Image().src = imagePath;
      });
    }
  }, [preloadedImages]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, t('INVALID_NAME')).required(),
    email: Yup.string().email(t('INVALID_EMAIL')).required(),
    linkedin: Yup.string().min(15, t('INVALID_LINKEDIN')).required(),
    message: Yup.string().max(2000, t('TOO_LONG_MESSAGE')),
  });

  const initialValues = {
    name: '',
    email: '',
    linkedin: '',
    message: '',
    newsletterAgreement: false,
  };

  const handleSubmit = (formData, { setSubmitting }) => {
    setIsPending(true);

    applyForJob(formData)
      .then((response) => {
        setFormState(response.ok ? formStates.SUCCESS : formStates.ERROR);
      })
      .catch(() => {
        setFormState(formStates.ERROR);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPending(false);
      });
  };

  let sidebarContent = null;

  if (formState === formStates.PRISTINE || formState === formStates.ERROR) {
    sidebarContent = (
      <Box
        width={1}
        bgcolor={isLgUp ? theme.palette.common.veryLightGray : 'unset'}
        borderRadius={12}
        p={isLgUp ? 8 : 6}
        pb={isLgUp ? 12 : 20}
        mt={isLgUp ? 15.5 : 0}
        position="relative"
      >
        {isPending && <Loader cover />}
        <Typography variant="h5">{t('SOUNDS_LIKE_YOU')}</Typography>
        <Box mt={2}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Field name="name">
                  {({ field }) => (
                    <>
                      <InputLabel
                        className={classes.label}
                        id="name-label"
                        error={errors.name && touched.name}
                      >
                        {t('YOUR_NAME')}*
                      </InputLabel>
                      <TextField
                        {...field}
                        variant={isLgUp ? 'variant2' : 'variant3'}
                        className={classes.input}
                        type="text"
                        error={errors.name && touched.name}
                        fullWidth
                      />
                    </>
                  )}
                </Field>
                <Field name="email">
                  {({ field }) => (
                    <>
                      <InputLabel
                        className={classes.label}
                        id="email-label"
                        error={errors.email && touched.email}
                      >
                        {t('YOUR_EMAIL')}*
                      </InputLabel>
                      <TextField
                        {...field}
                        variant={isLgUp ? 'variant2' : 'variant3'}
                        className={classes.input}
                        type="text"
                        error={errors.email && touched.email}
                        fullWidth
                      />
                    </>
                  )}
                </Field>
                <Field name="linkedin">
                  {({ field }) => (
                    <>
                      <InputLabel
                        className={classes.label}
                        id="linkedin-label"
                        error={errors.linkedin && touched.linkedin}
                      >
                        {t('YOUR_LINKEDIN')}*
                      </InputLabel>
                      <TextField
                        {...field}
                        variant={isLgUp ? 'variant2' : 'variant3'}
                        className={classes.input}
                        type="text"
                        error={errors.linkedin && touched.linkedin}
                        fullWidth
                      />
                    </>
                  )}
                </Field>
                <Field name="message">
                  {({ field }) => (
                    <>
                      <InputLabel className={classes.label} id="message-label">
                        {t('WHY_DO_YOU_WANT_TO_JOIN_US')}
                      </InputLabel>
                      <TextField
                        {...field}
                        variant={isLgUp ? 'variant2' : 'variant3'}
                        className={classes.input}
                        type="text"
                        error={errors.message && touched.message}
                        fullWidth
                        multiline
                        inputProps={{ maxLength: 2000 }}
                        rows={5}
                      />
                    </>
                  )}
                </Field>
                <Box display="flex" mb={isLgUp ? 6 : 9.5} mt={4} alignItems="center">
                  <Field name="newsletterAgreement">
                    {({ field }) => (
                      <Checkbox
                        {...field}
                        variant={isLgUp ? 'light' : 'dark'}
                        classes={{ root: classes.checkbox }}
                      />
                    )}
                  </Field>
                  <Typography variant="body2">{t('I_WOULD_LIKE_TO_RECEIVE_NEWSLETTER')}</Typography>
                </Box>
                {formState === formStates.ERROR && (
                  <Typography variant="h5">{t('FORM_SOMETHING_WENT_WRONG')}</Typography>
                )}
                <Box textAlign="right">
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || isPending}
                  >
                    {t('APPLY_NOW')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    );
  } else if (formState === formStates.SUCCESS) {
    sidebarContent = (
      <Box
        width={1}
        height={673}
        bgcolor={isLgUp ? theme.palette.common.veryLightGray : 'unset'}
        borderRadius={12}
        p={isLgUp ? 8 : 6}
        pb={12}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img alt="Message sent" src="/images/bulb-blue.svg" className={classes.accentImg} />
        <Typography variant="h2" align="center" className={classes.successHeading}>
          {t('WE_GOT_YOUR_INFORMATION')}
        </Typography>
        <Typography variant="body2" align="center">
          {t('WE_ARE_HAPPY_THAT_YOU_WROTE')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box width={1} display="flex" flexDirection={isLgUp ? 'row' : 'column'}>
      <Box className={classes.infoWrapper}>
        <Typography className={classes.title} variant="h4">
          {t(title)}
        </Typography>
        {description && (
          <Typography className={`${classes.strong} ${classes.description}`} variant="body1">
            {t(description)}
          </Typography>
        )}
        {paragraphs.map((paragraph) => (
          <Box key={paragraph.heading}>
            <Typography variant="body1" className={classes.heading}>
              {paragraph.heading}
            </Typography>
            {paragraph.bulletPoints.map((bulletPoint, index) => {
              return (
                <Box key={generateKey(index, bulletPoint)} display="flex" mb={4}>
                  <BulletPoint />
                  <Typography variant="body1" className={classes.bulletPoint}>
                    {bulletPoint}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ))}
        {link && (
          <>
            <Typography variant="body1" className={classes.heading}>
              {t('MORE_INFORMATION_ABOUT_POSITION')}:
            </Typography>
            <Typography variant="body1" component="a" href={link} className={classes.link}>
              {link}
              <OutboundIcon variant="dark" />
            </Typography>
          </>
        )}
      </Box>
      <Box width={isLgUp ? 404 : 1} minWidth={isLgUp ? 404 : 'unset'}>
        {sidebarContent}
      </Box>
    </Box>
  );
};
CareersItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    paragraphs: PropTypes.array,
    workingTime: PropTypes.string,
    workingRemote: PropTypes.string,
    location: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};

export { CareersItem };
